.db-social .jumbotron {
   margin-top: 15px;
   background-image: url(../../assets/img/bg-cli.png);
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
   background-position: bottom center;
   color: #fff!important;
   height: 300px;
   position: relative;
   box-shadow: inset 0 0 20px rgba(0,0,0,.3);
   padding: 0;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.container-fluid {
   padding: 30px 30px;
}

.db-social .head-profile {
   margin-top: 60px;
   border-radius: 4px;
   position: relative;
}

.widget {
   background: #fff;
   margin-bottom: 30px;
}

.db-social .head-profile:before {
   content: "";
   background: rgba(255,255,255,.6);
   height: 20px;
   width: 90%;
   position: absolute;
   top: -20px;
   left: 0;
   right: 0;
   margin: 0 auto;
   border-radius: 4px 4px 0 0;
}
.db-social .head-profile:after {
   content: "";
   background: rgba(255,255,255,.3);
   height: 20px;
   width: 80%;
   position: absolute;
   top: -40px;
   left: 0;
   right: 0;
   margin: 0 auto;
   border-radius: 4px 4px 0 0;
}
.db-social .widget-body {
   padding: 1rem 1.4rem;
}
.widget-body {
   padding: 1.4rem;
}
.pb-0, .py-0 {
   padding-bottom: 0!important;
}
.db-social .image-default img {
   /* width: 120px; */
   height: 130px;
   width: 130px;
   position: absolute;
   top: -80px;
   left: 0;
   right: 0;
   margin: 0 auto;
   box-shadow: 0 0 0 6px rgba(255,255,255,1);
   z-index: 10;
   object-fit: cover;
}
.db-social .infos {
   text-align: center;
   margin-top: 2.9rem;
   margin-bottom: 1rem;
   line-height: 1.8rem;
}
.db-social h2 {
   color: #2c304d;
   font-size: 1.6rem;
   font-weight: 600;
   margin-bottom: .2rem;
}
.db-social .location {
   color: #aea9c3;
   font-size: 1rem;
}
.db-social .follow .btn {
   padding: 10px 30px;
}
.btn:not(:disabled):not(.disabled) {
   cursor: pointer;
}
.btn-shadow, .btn-shadow a {
   color: #5d5386;
   background-color: #fff;
   border-color: #fff;
   box-shadow: 0 1px 15px 1px rgba(52,40,104,.15);
}
.db-social .head-profile .actions {
   display: inline-block;
   vertical-align: middle;
   margin-left: .5rem;
}
.actions {
   z-index: 999;
   display: block;
}
.actions.dark .dropdown-toggle {
   color: #2c304d;
}
.actions .dropdown-toggle {
   color: #98a8b4;
   background: none;
   border: none;
   padding: 0;
   font-size: 1.7rem;
}
.actions .dropdown-menu {
   border: none;
   min-width: auto;
   font-size: 1rem;
   border-radius: 4px;
   padding: 1.4rem 1.8rem;
   text-align: left;
   box-shadow: 1px 1px 30px rgba(0,0,0,.15);
}

.actions .dropdown-menu .dropdown-item {
   padding: .5rem 0;
}
.actions .dropdown-menu a {
   color: #2c304d;
   font-weight: 500;
}

.db-social .head-profile li:first-child {
   padding-left: 0;
}
.db-social .head-profile li {
   display: inline-block;
   text-align: center;
   padding: 0 1rem;
}
.db-social .head-profile li .counter {
   color: #2c304d;
   font-size: 1.4rem;
   font-weight: 600;
}
.db-social .head-profile li .heading {
   color: #aea9c3;
   font-size: 1rem;
}