:root{
  --primary:#26BF78;
  --primary-dark:#209d63;
  --secundary:#808080;
  --bg-primary:#26bf7824;
  --black:#000;
  --white:#fff;

}

/*?------  Generales -------*/
/* *Texts */
.text-primary-us{
  color: var(--primary);
}

.text-secondary-us{
  color: var(--primary);
}

.bg-primary-ushopper{
  color: black;
  background-color: var(--primary);
}

/* *Titles */

.label-input{
  font-size: 14.5px;
  font-weight: 500;
  color: var(--secundary);
}

.footer-title{
  font-size: 20px
}



/* *buttons */
.button-us{
  border: var(--primary);
  border-radius: 5px;
  margin:0px 0px 15px;
  padding:8px 16px;
  color: var(--white);
  font-size: 18px;
  background-color: var(--primary);
  margin-bottom: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.button-us:hover{
  color:var(--white);
  font-size: 18px;
  background-color:var(--primary-dark) ;

}



/*?------  Login -------*/
.main-login {
  background-color: var(--primary);

}
.form-signin {
  max-width: 500px;
  padding: 15px;
  padding-bottom: 80px;
}

.form-signin .form-floating:focus-within {
  color: var(--primary);
  z-index: 2;
  box-shadow: var(--primary);
}

.form-signin input[type="email"] {
  color: var(--primary);
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"],input[type="email"] {
  color: var(--primary);
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

input[type="text"]:focus, input[type="email"]:focus ,input[type="password"]:focus{
  box-shadow: 0 0 1px var(--primary);
  border: 1px solid var(--primary);
}

.form-floating label {
  color: var(--primary);
}

@media only screen and (max-width:768px) {
  .link{
    display: none;
  } 
}

.link-login{
  margin-top: 10px;
}

.link-login .link {  
  color: var(--primary);
}

.title-login, .links{
  color:var(--secundary);
  font-size: 19px;
}

/* Sing In  */
.sing-in-link{
  background-color: var(--bg-primary);
  color: var(--primary);
  padding: 8px 16px;
  font-size:  16px;
  text-decoration: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.sing-in-link:hover{
  background-color: var(--primary);
  color: #fff;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }

  .b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }

  /* .b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
  }

  .bi {
    vertical-align: -.125em;
    fill: currentColor;
  }

  .nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
  }

  .nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  } */



/* Sign Up */
.form-signup{
  max-width: 800px;
  padding: 15px;
  padding-bottom: 80px;
}

.sing-up-link{
  background-color: rgba(229, 229, 229, 0.569);
  color: white;
  padding: 8px 16px;
  font-size:  16px;
  text-decoration: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.sing-up-link:hover{
  color: var(--primary);
  background-color: white;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }

  .b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }
/* 
  .b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
  }

  .bi {
    vertical-align: -.125em;
    fill: currentColor;
  }

  .nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
  }

  .nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  } */


  /* Footer */
.footer-us{
  background-color: var(--primary);
  padding-bottom: 40px;
  width:100%;
} 