/* Font Family  */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
/** -----::::Variables::::------ **/
:root {
  --primary: #009EF7;
  /* --primary: #2ed06e; */
  --primary-dark: #209d63;
  --dark: #081828;
  --dark-2: #181C32;
  --secondary: #808080;
  --label: #c0c0c0c0;
  --lighten: #f8f8fc; 
  /* --bg-primary: #26bf7824; */
  --bg-primary: rgba(153, 153, 153, 0.1);
  --black: #000;
  --white: #fff;
  --gray: #f5f6fa;
  --red: #ea4545;
  --rose: #BF266D;
  --blue: #2647BF;
  --blue-mid: #266DBF;
  --blue-low: #26BABF;
  --bg-light-red: #eeacac63;
  --gray-light: #c0c0c0;
  --excel-color: #21633F;
  --light-bg: rgba(153, 153, 153, 0.1);
  --dark-bg: rgba(129, 129, 129, 0.3);
  --font-primary: 'Lato', sans-serif;
  --rounded: 10px;
  --rounded-1: 20px;
  
  --gray-05-invoice:#b5b5c3;
  --gray-10-invoice:#7E8299;
  --gray-20-invoice:#3F4254; 
 
}
/** -----::::End Variables::::------ **/

/** -----::::Generals ::::------ **/
body {
  position: relative;
  /* padding-bottom: 120px; */
  min-height: 100vh;
}

.main-section-us {
  max-width: 69rem;
  margin: 0;
  padding: 0 0rem;

}

/*? -----::::Seciones::::------ **/
/** -----::::NavBar Section ::::------ **/
.navbar{
  padding-bottom: 10px;  
}

.navbar-section{
  position: relative;
  top: 0;
  left: 0;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  margin: 2px auto;
  height: 50px;
  width: 100%; 
  background: transparent; 
}

.nabvar-logo-us {
  width: 137px;
  height: 39px;
}

.nabvar-avatar-us {
  background-color: var(--gray-light);
  width: 50px;
  height: 50px;
}

/*Profile navbar menu */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown .dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50px; /* Altura de la imagen */
  right: 15px;
}

.dropdown .dropdown-menu a {
  display: block;
  color: #000;
  padding: 10px;
  text-decoration: none;
}

.links-nav-us:hover{
  cursor: pointer;
  color: var(--white);
  background-color: var(--primary);
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-img {
  cursor: pointer;
}

/*End Profile navbar menu */
/** -----:::: End NavBar Section ::::------ **/
/** -----::::Header Section ::::------ **/
.main-header-us {
  background-image: url(./components/assets//img//header/header-bg.png);
  /* background-color: var(--primary); */
  background-position: center;
  background-size: 100% 170px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center top; 
}

@media (max-width: 991.98px) {
  .main-header-us {
    background-size: 100% 388px;  
  }
} 


.head-content {
  background-color: #ffffff;
  background-clip: border-box;
  display: flex;
  position: relative;
  margin-top: -80px;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
  border-radius: var(--rounded-1);
  border: 1px solid #dcdcdc;
  -webkit-border-radius: var(--rounded-1);
  -moz-border-radius: var(--rounded-1);
  -ms-border-radius: var(--rounded-1);
  -o-border-radius: var(--rounded-1);
}

.head-us {
  padding-bottom: 40px;
  margin-bottom: 50px;
}

.head-title-us{
  color: var(--bg-primary);
}

/** -----::::End Header Section ::::------ **/
.bg-prueba {
  background-image: linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100%);
}

/** -----::::End Generals ::::------ **/
/** -----::::Backround::::------ **/
.bg-ushopper {
  background-color: var(--primary);
}

.bg-btn-secundary {
  background-color: var(--gray);
}

.bg-white {
  background-color: var(--white);
}

.bg-gradient-profile {
  background-color: var(--bg-primary);
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.card-us{  
  
    flex: 1 1 auto;
    padding: 1rem 1rem;
}

/** -----::::Main Content::::------ **/
/** -----::::End Main Content::::------ **/

/** -----::::Titles::::------ **/
.font-us {
  font-family: var(--font-primary);
}

.main-title {
  font-family: var(--font-primary);
  font-size: 35px;
}

.sub-title {
  color: #1c1e21;
  font-size: 25px;
  font-family: var(--font-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-input {
  font-size: 14.5px;
  font-weight: 500;
  color: var(--secundary);
}

.label-btn {
  font-weight: 800;
  font-size: 16px;

}

.footer-title {
  font-size: 20px;
}
/** -----::::End Variables::::------ **/

/* ? ----::::: Utils:::::---- */
/** -----::::Icons::::------ **/
.icon-m {
  margin-right: 2px;
  margin-bottom: 3px;
}
/** -----::::End Icons::::------ **/

/** -----::::Serch Input::::------ **/
.search-section-us{
  display: flex ;
  justify-content: center;
  align-items: center;
}

.us-search .form-control {
  padding-left: 2.375rem;
  width: 350px;
}

.us-search .form-control-feedback {
  transform: scale(0.5);
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
}

@media only screen and (max-width: 600px) {
  .us-search .form-control {
    padding-left: 2.375rem;
    width: 250px;
  }
}

/** -----::::End Serch Input::::------ **/
/** -----::::Forms Input::::------ **/

.question-us{  
  font-weight: 600;
  font-size: 30px;
}
/** -----::::End Forms Input::::------ **/

.table-us {
  color: var(--gray);
  background-color: var(--primary);
}

.scroll-table-data {
  width: auto;
  height: auto;
  overflow: scroll;
}


/*  Header  */
.header-user-name {
  background-color: transparent;
  border: 2px var(--white) solid;
}

.pdf-viewer-sp {
  width: 100%;
  height: 90vh;
}

/* *Texts Colors */
.name-us-profile {
  color: rgba(255, 255, 255, .5) !important;
}

.text-ushopper {
  color: var(--primary);

}

.text-primary-us {
  color: var(--primary);
}

.text-secondary-us {
  color: var(--secondary);
}

.text-gray {
  color: var(--gray-light);
}

.bg-primary-ushopper {
  color: black;
  background-color: var(--primary);
}

.rounded-us {
  border-radius: 8px !important;
  border: 1px solid var(--dark-bg);
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.rounded-card,
.rounded-us-card {
  border: 1px solid var(--gray);
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}

.rounded-us-card:hover {
  border: 2px solid var(--primary);
}

/* *buttons */
.button-us {
  font-size: 18px;
}

.btn-sm-us {
  font-size: 15px;
  font-family: var(--font-primary);
}

.button-us,
.btn-sm-us {
  font-family: var(--font-primary);
  border: var(--primary);
  border-radius: var(--rounded);
  margin: 0px 0px 15px;
  padding: 8px 12px;
  color: var(--white);
  background-color: var(--primary);
  -webkit-border-radius: var(--rounded);
  -moz-border-radius: var(--rounded);
  -ms-border-radius: var(--rounded);
  -o-border-radius: var(--rounded);
}

.button-us:hover,
.btn-sm-us:hover {
  color: var(--white);
  background-color: var(--primary-dark);
  border-radius: var(--rounded);
  -webkit-border-radius: var(--rounded);
  -moz-border-radius: var(--rounded);
  -ms-border-radius: var(--rounded);
  -o-border-radius: var(--rounded);
}

.btn-us-add {
  color: var(--primary);
  font-weight: 700;
}

.btn-us-add:hover {
  color: var(--primary-dark);
  background-color: var(--secundary);
  font-weight: 700;
}

.btn-exit {
  color: var(--primary) !important;
  background-color: var(--bg-primary);
  padding: 10px 10px;
  text-decoration: none;
  border: 1px solid #ffffff;
  border-radius: var(--rounded);
  -webkit-border-radius: var(--rounded);
  -moz-border-radius: var(--rounded);
  -ms-border-radius: var(--rounded);
  -o-border-radius: var(--rounded);
}


.btn-edit {
  color: var(--blue-mid);
  background-color: var(--white);
  padding: 10px 10px;
  border: 1px solid #ffffff;
  border-radius: var(--rounded);
  -webkit-border-radius: var(--rounded);
  -moz-border-radius: var(--rounded);
  -ms-border-radius: var(--rounded);
  -o-border-radius: var(--rounded);
}

.btn-exit:hover {
  /*letter-spacing: 0.5px;  
  transform: scale(0.7);*/
  color: var(--primary);
  background-color: var(--bg-primary);
}

.btn-edit:hover {
  background-color: var(--gray);

}

.btn-secondary-us {
  color: var(--primary);
}

.btn-secondary-us:hover {
  color: var(--primary);
  background-color: var(--bg-primary);
  border: 1px solid var(--primary);
}

.btn-danger-us:hover {
  background-color: var(--bg-light-red);
  border: 1px solid var(--red);
}

.btn-exel {
  background-color: var(--excel-color);
}


.btn-p {
  margin-right: 10px;
  margin-bottom: 3px;
}

.logo-ushopper {
  width: 210px;
  height: 56px;
  margin-bottom: 10px;
}




.img-card-us {
  width: 130px;
  height: 130px;
}

.img-profile-nav {
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  background-color: var(--secondary);
}

/*?------  Login -------*/
.main-login {
  background-color: var(--primary);
}

.form-signin {
  max-width: 500px;
  /* padding: 15px; */
  padding-bottom: 0px;
}


@media only screen and (max-width: 576px) {
  .main-section-us {
    /* max-width: 75rem; */
    /* margin: 0 auto; */
    padding: 0 0rem;
  }

}


@media (min-width: 768px) {
  .form-signin {
    padding-bottom: 250px;
  }
}

@media (min-width: 820px) {
  .form-signin {
    padding-bottom: 350px;
  }
}

.form-signin .form-floating:focus-within {
  color: var(--primary);
  z-index: 2;
  box-shadow: var(--primary);
}

.form-signin input[type="email"] {
  color: var(--primary);

  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"],
input[type="email"] {
  color: var(--primary);
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  box-shadow: 0 0 1px var(--primary);
  border: 1px solid var(--primary);
}

.form-floating label {
  color: var(--primary);
}

@media only screen and (max-width: 768px) {
  .link {
    display: none;
  }
}

.link-login {
  margin-top: 10px;
}

.link-login .link {
  color: var(--primary);
}

.title-login,
.links {
  color: var(--secundary);
  font-size: 19px;
}

/* Sing In  */
.sing-in-link {
  background-color: var(--bg-primary);
  color: var(--primary);
  padding: 8px 16px;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.sing-in-link:hover {
  background-color: var(--primary);
  color: #fff;
}

.img-profile-user {
  width: 160px;
  height: 160px;
  border: 3px solid var(--gray);
  border-radius: 90px;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  -ms-border-radius: 90px;
  -o-border-radius: 90px;
}


.bd-placeholder-img {
  background-color: var(--bg-primary);
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

/* Pills */
.pills-us {
  background-color: var(--blue-mid);
  border: none;
  color: var(--white);
  padding: 10px 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: var(--rounded);
  -webkit-border-radius: var(--rounded);
  -moz-border-radius: var(--rounded);
  -ms-border-radius: var(--rounded);
  -o-border-radius: var(--rounded);
}

.pills-us:hover {
  background-color: var(--blue);
}



.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

/* Sign Up */
.form-signup {
  max-width: 800px;
  padding: 15px;
  padding-bottom: 80px;
}

.sing-up-link {
  background-color: rgba(229, 229, 229, 0.569);
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.sing-up-link:hover {
  color: var(--primary);
  background-color: white;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(36, 29, 29, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.title-us {
  color: var(--primary);
  text-decoration: none;
}

.card-up {
  position: relative;
  top: 0;
  transition: top ease 0.4s;
  -webkit-transition: top ease 0.4s;
  -moz-transition: top ease 0.4s;
  -ms-transition: top ease 0.4s;
  -o-transition: top ease 0.4s;
}

.card-up:hover {
  border-color: red;
  top: -10px;
}

.img-table {
  max-width: 100%;
  border-radius: var(--rounded);
  object-fit: cover;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 75px;
  height: 75px;
  -webkit-border-radius: var(--rounded);
  -moz-border-radius: var(--rounded);
  -ms-border-radius: var(--rounded);
  -o-border-radius: var(--rounded);
}

.img-modal {
  width: 150px;
  height: 150px;
}

/* Headers */
.head-section-us {
  height: 205px;
  width: 100%;
  border: 1px solid;
  color: #fff;
  /* background-color: var(--primary); */
  background-image: url(./components/Dashboard/Clientes/bg-cli.png);
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--rounded);
}

.head-section-us-profile {
  height: 400px;
  width: 25%;
  border: 1px solid;
  color: #fff;
  /* background-color: var(--primary); */
  background-image: url(./components/Dashboard/Clientes/bg-cli.png);
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--rounded);
}

@media (max-width: 988px) {

  .head-section-us {
    border: 1px solid #fff;
    color: var(--primary);
    background-color: #fff;
    background-image: none;
    border-radius: var(--rounded);
  }
}

/* Profile card */
.card-profile-us {
  border-radius: 90px;
  width: 150px;
  height: 150px;
  background-color: var(--black);
  position: absolute;
  top: 100px;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  -ms-border-radius: 90px;
  -o-border-radius: 90px;
}

.name-user-us {
  display: block;
  margin-top: 35px;
}

.card {
  width: 100%;
  /* border: 1px solid var(--primary); */
  border-radius: 15px;
  padding: 8px;
  background-color: #fff;
  position: relative;
  height: 370px;
}

.upper {
  height: 100px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.main-title-section {
  color: var(--dark-2);
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 1.275rem;

}

.body-card-us {
  max-width: 100%;
  border: 1px solid #a9acaa63;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

@media (max-width: 768px) {
  .container-cards {
    margin: 0px 0px;
    padding: 24px 0px;
    /* border: 1px solid var(--gray-light);
    border-radius: 10px  ; */

  }

  .body-card-us {
    margin-top: 5px;

  }

  .main-title-section {
    font-size: 29px;
  }
}

.options-menu-cards {
  max-width: 100%;
  margin: 0px 0px;
}


.title-card {
  padding: 7px 0px;
  color: #1f1f1f;
  font-size: 16px;
  line-height: 20px;
  margin: 8px 0px;
  text-align: center;
}

.img-card-us {
  max-width: 100%;
  width: 84px;
  height: 84px;
}

/* Animations  */
.fade-in-top {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s ease-in backwards;
  animation: fade-in-bottom 0.6s ease-in backwards;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards;
}



@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* Boton Flotante */
.btn-flotante {
  font-size: 17px;
  color: var(--white);
  border-radius: 5px;
  letter-spacing: 2px;
  background-color: var(--black);
  padding: 10px 30px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 99;
  text-decoration: none;
}

.btn-flotante:hover {
  color: var(--gray);
  background-color: var(--primary-dark);
  /* Color de fondo al pasar el cursor */
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
}

@media only screen and (max-width: 600px) {
  .btn-flotante {
    font-size: 14px;
    padding: 12px 20px;
    bottom: 20px;
    right: 20px;
  }
}

/* Chck List style */

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.opacity-50 {
  opacity: .5;
}

.opacity-75 {
  opacity: .75;
}

.list-group {
  width: auto;
  max-width: 460px;
  margin: 0.5rem auto;
}

.form-check-input:checked+.form-checked-content {
  opacity: .5;
}

.form-check-input-placeholder {
  pointer-events: none;
  border-style: dashed;
}

[contenteditable]:focus {
  outline: 0;
}

.list-group-checkable {
  display: grid;
  gap: 0;
  border: 0;
}

.list-group-checkable .list-group-item {
  background-color: var(--white);
  cursor: pointer;
  border: 1px solid rgba(219, 219, 219, 0.334);
  box-shadow: 0px 13px 11px -8px rgba(198, 198, 198, 0.3);
  -webkit-box-shadow: 0px 13px 11px -8px rgba(198, 198, 198, 0.3);
  border-radius: var(--rounded);
  -webkit-border-radius: var(--rounded);
  -moz-border-radius: var(--rounded);
  -ms-border-radius: var(--rounded);
  -o-border-radius: var(--rounded);
}

.list-group-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.list-group-item-check:hover+.list-group-item {
  background-color: var(--bs-light);
}

.list-group-item-check:checked+.list-group-item {
  color: #fff;
  background-color: var(--bs-blue);
}

.list-group-item-check[disabled]+.list-group-item,
.list-group-item-check:disabled+.list-group-item {
  pointer-events: none;
  filter: none;
  opacity: .5;
}


/* Footer */
.footer-us {
  background-color: var(--primary);
  width: 100%;
  position: absolute;
  bottom: 0;
}




/* Cards simple */
a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.drop {
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
}

.drop__container {
  display: grid;
  row-gap: 1rem;
  padding: 1.5rem;
  box-shadow: 4px 4px 16px #e1e1e1;
  border-radius: 1rem;
}

.drop__list {
  display: grid;
  row-gap: 1rem;
}

.drop__card,
.drop__data {
  display: flex;
  align-items: center;
}

.drop__card {
  width: 360px;
  justify-content: space-between;
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  background-color: var(--first-color-lighten);
  box-shadow: 4px 4px 16px #e1e1e1, -2px -2px 16px #fff;
  border-radius: 1rem;
}

.drop__img {
  width: 55px;
  height: 55px;
  border-radius: 1rem;
  margin-right: 1rem;
}

.drop__name {
  font-size: var(--normal-font-size);
  color: var(--first-color);
  font-weight: 500;
}

.drop__profession {
  font-size: var(--smaller-font-size);
  color: var(--first-color-light);
}

.drop__social {
  margin: 0 0.375rem;
  color: var(--first-color-light);
  transition: 0.4s;
}

.drop__social:hover {
  color: var(--first-color);
}

.sortable-chosen {
  box-shadow: 8px 8px 32px #e1e1e1;
  cursor: grabbing;
}

.sortable-drag {
  opacity: 0;
}

/* ?Biller  */

.sub-title-biller {
  font-size: 15px;
  color: var(--dark-2);
}

.data-profile {
  text-overflow: ellipsis;
  overflow: hidden;

}

/** -----::::Invoice Ushopper::::------ **/
.invoice-us{
  background-color: transparent;
}

.invoice-us-dates{
  padding: 5px 3px;
  border-style: dashed;
  border-color: #E4E6EF;
  border-radius: 0.475rem;
}

.invoice-logo{
  margin-top: 5px;
  width: 130px;

}

/** -----::::End Invoice Ushopper::::------ **/
/** -----::::Modals info Busiiness  Ushopper::::------ **/
.info-modal-bussinsess{
  cursor: pointer;
}


.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon:nth-last-of-type(2) {
  border-bottom: none;
  display: none;
}


/* Form New Client  */
.form-type-user{

}

.start-us-0{
  left: 0%!important;
}

.start-us-10{
  left: 33%!important;
}

.start-us-20{
  left: 66%!important;
}

.start-us-30{
  left: 100%!important;
}

.css-1nrlq1o-MuiFormControl-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 13px;
  margin: 0;
  border: 0;
  vertical-align: top;
}

/* Form Biller  */
.fact-label-form{
  color: var(--dark);
  font-weight: 500;
}