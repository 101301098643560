.transaction-text{
   font-size: 19px;
   font-weight: 500;
}

.border-sp {
   background-color: #fff;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.bg-transaction{
   background-color: #f8f9fa46;

}

.bg-total {
   background-color: rgb(17, 203, 98);
}