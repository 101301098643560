@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap');

/* Variables  */
:root{
    --primary:#22B472;
    --primary-1:#084DA6;
    --primary-2:#e9fcee;
    --primary-dark:hsl(214, 97%, 13%);
    --white: #fff;
    --black:#0D0D0D;
    --yellow:#ffd51b;
    --light-blue:#D9EEEA;
}

/* General  */

*{
    font-family: 'Roboto', sans-serif;
}
.color-ushopper{
    color: var(--primary);
}

.color-bg-ushopper{
    background-color: var(--primary) !important;
}

.color-bg-ushopper2{
    background-color: var(--primary-1) !important;
}

/* Nav Bar */
.bg-primary-ushopper{
    color: var( --white);
    background-color: var( --primary);
}

.navbar-title{
    font-weight: bold;
    color: var(--primary);
    text-decoration: none;
}

.img-logo{
    width: 125px;
    height: 40px;
    padding-bottom:5px;

}

/* Hero Section */
.hero-ushopper{
    padding: 100px 40px ;
}

.hero-banner{
    font-weight: 300;
}

.bg-color-sencudary{
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 9%, rgba(233,252,238,1) 100%);
}

.title-hero-ushopper{
    font-size: 48px;
    font-weight: bold;
}

.slider{
 color: var(--primary-1);
 font-size: 40px;
 font-weight: bold;
 text-transform: uppercase;
}

.slide{
        overflow: hidden;
        height: 60px;
   }

.slide div div {
    display: inline-block;
    color:var(--primary);
    background-color: transparent;
    padding: 4px 0px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    margin-bottom: 45px;
}
   


.slide div:nth-child(1){
    animation:animate 7s linear infinite;
    -webkit-animation:animate 7s linear infinite;
}
@keyframes animate {
    0%{ margin-top: -270px; }
    5%{ margin-top: -180px; }
    33%{ margin-top: -180px; }
    38%{ margin-top: -90px; }
    60%{ margin-top: -90px; }
    71%{ margin-top: 0px; }
    99.99%{ margin-top: 0px; }
    100%{ margin-top: -270px; }
}

.content-img{
    position: relative;

}
/* imge */
.img-hero{
    
    width:320px ;
    height:220px ;

}

.img-animation-ushopper {
    width: 168px;
    height: 298px;
    position: absolute;
    top: 88px;
    right: 123px;
}

@media only screen and (max-width: 360px) {
    .img-animation-ushopper {
        width: 103px;
        height: 182px;
        position: absolute;
        top: 53px;
        right: 134px;
    }
  }

@media only screen and (max-width: 764px) {
    .img-animation-ushopper {
        width: 104px;
        height: 182px;
        position: absolute;
        top: 53px;
        right: 141px;
    }
  }

@media only screen and (max-width: 600px) {
    .img-hero{
        width:195px ;
    }

    .title-hero-ushopper{
        font-size: 34px;
        font-weight: bold;

    }
  }


.nav-link-ushopper{
    color: var(--primary-2);

}
.nav-link-ushopper:hover{
    font-weight: bold;
    color: var(--primary-2);

}

.rounded-us{
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
}


/* ABout  */

.img-about{
    width: 140px ;
    height: 150px;

}

.p-ushopper{
    color: #b3b3b3;
    font-size: 20px;
}

.icon-feat{
    width: 70px;
    height: 70px;

}

.bg-feat{
    background-image: url(./img/bg-feat.jpg) !important;
    width: 120px;
    height: 120px;
}

.bg-logo{
    width: 240px;
    height: 120px;
}
/* Boton Float  */

.img-float-whatsapp{
    width: 35px;
    height: 35px;

}
.btn-flotante {
    color: #ffffff;
    border: 1px solid var(--light-blue);
    border-radius: 50px ;
    background-color: var(--primary);
    padding: 0px 15px;
    /*Rellenodelboton*/position: fixed;
    bottom: 20px;
    right: 10px;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 18px 15px rgba(0, 0, 0, 0.1);
    z-index: 99;
    -webkit-transition: all 300ms ease 0ms;
    -moz-transition: all 300ms ease 0ms;
    -ms-transition: all 300ms ease 0ms;
    -o-transition: all 300ms ease 0ms;
}
    .btn-flotante:hover { 
        background-color: var(--primary-1); /* Color de fondo al pasar el cursor */
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
        transform: translateY(-7px);
    }

    @media only screen and (max-width: 600px){
    .btn-flotante {
        font-size: 14px;
        /* padding: 12px 20px; */
        bottom: 20px;
        right: 14px;
    }

}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .marketing h2 {
    font-weight: 400;
  }
  /* rtl:begin:ignore */
  .marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
  }
  /* rtl:end:ignore */
  
  
  /* Featurettes
  ------------------------- */
  
  .featurette-divider {
    margin: 5rem 0; /* Space out the Bootstrap <hr> more */
  }
  
  /* Thin out the marketing headings */
  .featurette-heading {
    font-weight: 300;
    line-height: 1;
    /* rtl:remove */
    letter-spacing: -.05rem;
  }
  
  
  /* RESPONSIVE CSS
  -------------------------------------------------- */
  
  @media (min-width: 40em) {
    /* Bump up size of carousel content */
    .carousel-caption p {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.4;
    }
  
    .featurette-heading {
      font-size: 50px;
    }
  }
  
  @media (min-width: 62em) {
    .featurette-heading {
      margin-top: 7rem;
    }
  }
  
.box-img{
    width: 100%;
    height: auto
}

  .img-team{   
    object-fit: cover ;

  }

.snap-container{

    scroll-snap-type: y mandatory;
}

/* Area de contacto  */

.contact-area{
    margin: 12px 56px;
}


.contact-area .title-contact{
    font-size: 500px;
}
